exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-booking-js": () => import("./../../../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-gavekort-js": () => import("./../../../src/pages/gavekort.js" /* webpackChunkName: "component---src-pages-gavekort-js" */),
  "component---src-pages-gavekort-success-js": () => import("./../../../src/pages/gavekort_success.js" /* webpackChunkName: "component---src-pages-gavekort-success-js" */),
  "component---src-pages-hvor-er-vi-js": () => import("./../../../src/pages/hvor-er-vi.js" /* webpackChunkName: "component---src-pages-hvor-er-vi-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobb-hos-oss-js": () => import("./../../../src/pages/jobb-hos-oss.js" /* webpackChunkName: "component---src-pages-jobb-hos-oss-js" */),
  "component---src-pages-kan-jeg-bruke-sminke-js": () => import("./../../../src/pages/kan-jeg-bruke-sminke.js" /* webpackChunkName: "component---src-pages-kan-jeg-bruke-sminke-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-landing-naturlig-volum-js": () => import("./../../../src/pages/landing_naturlig_volum.js" /* webpackChunkName: "component---src-pages-landing-naturlig-volum-js" */),
  "component---src-pages-landing-vippeextensions-js": () => import("./../../../src/pages/landing_vippeextensions.js" /* webpackChunkName: "component---src-pages-landing-vippeextensions-js" */),
  "component---src-pages-landing-vippeloft-js": () => import("./../../../src/pages/landing_vippeloft.js" /* webpackChunkName: "component---src-pages-landing-vippeloft-js" */),
  "component---src-pages-personvern-js": () => import("./../../../src/pages/personvern.js" /* webpackChunkName: "component---src-pages-personvern-js" */),
  "component---src-pages-pfyll-fra-andre-steder-js": () => import("./../../../src/pages/pfyll-fra-andre-steder.js" /* webpackChunkName: "component---src-pages-pfyll-fra-andre-steder-js" */),
  "component---src-pages-priser-js": () => import("./../../../src/pages/priser.js" /* webpackChunkName: "component---src-pages-priser-js" */),
  "component---src-pages-rens-av-vippeextensions-js": () => import("./../../../src/pages/rens-av-vippeextensions.js" /* webpackChunkName: "component---src-pages-rens-av-vippeextensions-js" */),
  "component---src-pages-smittevern-js": () => import("./../../../src/pages/smittevern.js" /* webpackChunkName: "component---src-pages-smittevern-js" */),
  "component---src-pages-vippeextensions-js": () => import("./../../../src/pages/vippeextensions.js" /* webpackChunkName: "component---src-pages-vippeextensions-js" */),
  "component---src-pages-vippeextensionsvarianter-js": () => import("./../../../src/pages/vippeextensionsvarianter.js" /* webpackChunkName: "component---src-pages-vippeextensionsvarianter-js" */),
  "component---src-pages-vippeloft-js": () => import("./../../../src/pages/vippeloft.js" /* webpackChunkName: "component---src-pages-vippeloft-js" */),
  "component---src-pages-vippeloft-vs-vippeextensions-js": () => import("./../../../src/pages/vippeloft-vs-vippeextensions.js" /* webpackChunkName: "component---src-pages-vippeloft-vs-vippeextensions-js" */)
}


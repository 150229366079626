exports.onRouteUpdate = ({ location, prevLocation }) => {
    if (typeof gtag !== 'function'){
        //console.log("gtag not a function");
        return null;
    }

    if (!prevLocation){
        // The configuration takes care of the first report
        // so if threre is no previous location simply exit
        //console.log("Fisrt nav", location.pathname);
        return null;
    }

    const sendPageView = () => {
        const pagePath = location
            ? location.pathname + location.search + location.hash
            : undefined
        window.gtag(`event`, `page_view`, { page_path: pagePath })
        //console.log("nav", pagePath);
    }

    // wrap inside a timeout to make sure react-helmet is done with its changes (https://github.com/gatsbyjs/gatsby/issues/11592)
    if (`requestAnimationFrame` in window) {
        requestAnimationFrame(() => {
            requestAnimationFrame(sendPageView);
        })
    } else {
        // simulate 2 rAF calls
        setTimeout(sendPageView, 32);
    }

    return null;
};